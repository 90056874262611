import React, { useState, useEffect } from 'react';
import {useAuth, useEntity, useFetch} from 'react-admin-base';
import { EntityEditor, SingleFilePicker, Validator } from 'react-admin-base-bootstrap';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import { LanguageSelect } from '../../Components/Selects';
import { useUser } from '../../Components/UserProvider';
import {Breadcrumb} from "react-admin-base-front";
import {useParams} from "react-router-dom";
import {FormattedMessage} from "react-intl";

function useFreeFetch(url, params) {
    const [ api ] = useAuth();
    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    useEffect(function() {
        async function update() {
            setLoading(true);
            try {
                const newData = await api.free.get(url, {params: params});
                setData(newData.data);
            } finally {
                setLoading(false);
            }
        }

        if (!url) {
            setData(null);
        } else {
            update();
        }
    }, [api, url, params]);

    return [ data, loading ];
}

export default function LanguageEntity() {
    const { id } = useParams();
    const user = useUser();
    const entity = useEntity('/language', id, { values: { } });
    const [data, setData] = entity;

    const [ keys ] = useFetch('/language/keys');
    const [ messages ] = useFreeFetch(data.parent && data.parent.precompiled_json && data.parent.precompiled_json.access_url);

    if (!data || !keys) {
        return null;
    }

    return <Breadcrumb
        data={
            [
                {
                    href: '/language',
                    name: 'Sprachen'
                },
                {
                    href: id ? '/language/' + data.id + '/edit' : '/language/create',
                    name: id ? 'Bearbeiten' : 'Erstellen'
                }
            ]
        }
    >
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <FormGroup>
                        <Label><FormattedMessage id="LANGUAGE"/></Label>
                        <Validator name="name" type="required">
                            <Input
                                type="text"
                                value={data.name || ''}
                                onChange={a => setData({name: a.currentTarget.value})}
                            />
                        </Validator>
                    </FormGroup>
                    <Row>
                        <Col md="9">
                            <FormGroup>
                                <Label><FormattedMessage id="LANGUAGE"/></Label>
                                <Validator name="language_name" type="required">
                                    <Input
                                        type="text"
                                        value={data.language_name || ''}
                                        onChange={a => setData({language_name: a.currentTarget.value})}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label><FormattedMessage id="LANGUAGE_CODE"/></Label>
                                <Validator name="language_code" type="required">
                                    <Input
                                        type="text"
                                        value={data.language_code || ''}
                                        onChange={a => setData({language_code: a.currentTarget.value})}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label><FormattedMessage id="ICON"/></Label>
                        <SingleFilePicker
                            accepts="image/*"
                            value={data.icon}
                            onChange={a => setData({icon: a})}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="PARENT_LANGUAGE"/></Label>
                        <LanguageSelect
                            filter={row => !id || (row.id !== +id)}
                            value={data.parent}
                            onChange={a => setData({parent: a})}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <b><FormattedMessage id="PARENT_LANGUAGE"/></b>
                </CardHeader>
                <CardBody>
                    <Row>
                        { keys.map(b => <Col md="6" key={b.key}>
                            <FormGroup>
                                <Label>{b.key}</Label>
                                <Input
                                    type="text"
                                    value={data.values[b.key] || ''}
                                    onChange={a => setData({ values: { ...data.values, [b.key]: a.currentTarget.value || null } })}
                                    placeholder={messages && messages[b.key]}
                                />
                                { !!b.description && <FormText color="muted">
                                    { b.description }
                                </FormText> }
                            </FormGroup>
                        </Col>) }
                    </Row>
                </CardBody>
            </Card>
        </EntityEditor>
    </Breadcrumb>;
}
