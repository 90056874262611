import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import React, {useCallback, useState} from 'react';
import {useAuth} from 'react-admin-base';
import {Button} from "reactstrap";
import DataSourceEntity from "./DataSourceEntity";
import {FormattedMessage} from "react-intl";

export default function DataSources() {
    return <Breadcrumb
        title="Data Sources"
        data={
        [
            {
                href: '/data-source',
                name: 'Data Sources'
            }
        ]
    }
        >
        <CRUD url="/data-source" Component={DataSourceEntity}>
            <thead>
                <tr>
                    <IdColumn />
                    <Column sort="name"><FormattedMessage id="NAME"/></Column>
                    <ActionsColumn />
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/data-source/" + row.id}
                     />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
