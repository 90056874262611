
import React from 'react';
import { useCallback, useState } from 'react';
import { useAuth } from "react-admin-base";
import { Header } from 'react-admin-base-front';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useUser } from "./Components/UserProvider";
import {Link} from "react-router-dom";
import { useLanguage } from 'react-admin-base-bootstrap';
import {FormattedMessage, useIntl} from "react-intl";
import Swal from 'sweetalert2';

function UserPart() {
    const user = useUser();
    const [api] = useAuth();
    const [show, setShow] = useState(false);

    const logout = useCallback(function () {
        api.logout();
    }, [api]);

    return <Dropdown isOpen={show} toggle={() => setShow(!show)}>
        <DropdownToggle nav caret>
            <FormattedMessage id="HELLO"/>,{' '}<b>{user.name }</b>
        </DropdownToggle>
        <DropdownMenu right>
            <DropdownItem onClick={logout}><FormattedMessage id="EXIT"/></DropdownItem>
        </DropdownMenu>
    </Dropdown>;
}

export default function MenuHeader({ isFromSession }) {
    return <Header>
        { !isFromSession && <Link className="text-muted" to="/">
            <FormattedMessage id="DASHBOARD"/>
        </Link> }

        <div className="navbar-collapse collapse">
            <ul className="navbar-nav navbar-align justify-content-end">
                <LanguageSwitcher isFromSession={isFromSession} />
                <UserPart />
            </ul>
        </div>
    </Header>;
}

function LanguageSwitcher({ isFromSession }) {
    const [show, setShow] = useState(false);
    const [ activeLang, languages, setLanguage ] = useLanguage();

    const intl = useIntl();

    const handleSetLanguage = useCallback(async function(key) {
        if (isFromSession) {
            const val = await Swal.fire({
                title: intl.formatMessage({ id: 'ACTIONS.DELETE.TITLE' }),
                text: intl.formatMessage({ id: 'ACTIONS.DELETE.TEXT' }),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: intl.formatMessage({ id: 'ACTIONS.DELETE.CONFIRM' })
            });

            if (!val.value)
                return;
        }

        setLanguage(key);
    }, [isFromSession, setLanguage]);

    if (Object.keys(languages).length <= 1)
        return null;

    return <Dropdown isOpen={show} toggle={() => setShow(!show)}>
        <DropdownToggle className="nav-flag" nav caret>
            { activeLang.icon && <img width="20" className="me-1" src={ activeLang.icon } alt={activeLang.name} /> } {activeLang.name}
        </DropdownToggle>
        <DropdownMenu>
            { Object.entries(languages).map(([key, value]) => <DropdownItem key={key} active={activeLang === value} onClick={() => handleSetLanguage(key)}>
                {value.icon && <img width="20" className="me-1" src={value.icon} alt={value.name} /> } <span className="align-middle">{ value.name }</span>
            </DropdownItem>) }
        </DropdownMenu>
    </Dropdown>;
}