import React from 'react';
import {Sidebar, Menu, MenuGroup} from 'react-admin-base-front';
import {useUser} from './Components/UserProvider';
import {FormattedMessage} from "react-intl";

export default function MenuSidebar() {
    const user = useUser();

    return <Sidebar>
        <MenuGroup title={<FormattedMessage id="PAGES"/>}>
            <Menu title={<FormattedMessage id="FEEDS"/>} to="/feed" icon="fas fa-rss"/>
            <Menu title={<FormattedMessage id="ADDRESSES"/>} to="/address" icon="fas fa-address-book"/>
            <Menu title={<FormattedMessage id="ORDERS"/>} to="/order" icon="fas fa-basket-shopping"/>

            <Menu title={<FormattedMessage id="MY_PROFILE"/>} to="/profile" icon="fas fa-user"/>
        </MenuGroup>

        {user.type === 512 && <MenuGroup title={<FormattedMessage id="ADMIN"/>}>
            <Menu title={<FormattedMessage id="COUNTRIES"/>} to="/country" icon="fas fa-flag"/>
            <Menu title={<FormattedMessage id="USERS"/>} to="/user" icon="fas fa-users"/>
            <Menu title={<FormattedMessage id="DATA_SOURCE"/>} to="/data-source" icon="fas fa-link"/>
            <Menu title={<FormattedMessage id="LANGUAGE_FEATURES"/>} to="/language" icon="fas fa-language"/>
        </MenuGroup>}
    </Sidebar>;
}
