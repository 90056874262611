import {useParams} from "react-router-dom";
import {useUser} from "../../Components/UserProvider";
import {MultiValue, useEntity, useFetch} from "react-admin-base";
import {ModalEntityEditor} from "react-admin-base-bootstrap";
import {Button, Col, Input, ModalBody, Row, Table} from "reactstrap";
import AddressInput from "../../Components/AddressInput";
import React, {useState} from "react";
import {AddressSelect, FeedSelect, UserSelect} from "../../Components/Selects";
import {Field} from "../../Components/Fields";
import ProductInput from "../../Components/ProductInput";
import Money from "../../Components/Money";
import {FormattedMessage} from "react-intl";

export default function OrderEntity(props) {
    const [ feeds ] = useFetch('/feed');

    if (!feeds)
        return null;

    return <OrderEntityInner feed={(feeds.data.length === 1 && feeds.data[0]) || null} {...props} />;
}

function OrderEntityInner({feed, url, onReload}) {
    const {id} = useParams();
    const user = useUser();
    const entity = useEntity('/order', id, { feed, user});
    const [data, setData] = entity;

    return <ModalEntityEditor size="xl" title="Order Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody className="pb-0">
            {user.type === 512 && <Field title="USER">
                <UserSelect
                    value={data.user}
                    onChange={user => setData({user})}
                />
            </Field>}
            <Row className="py-2">
                <Col className="py-2" style={{ backgroundColor: '#FFFFE0' }}>
                    <Field title="BILLING_ADDRESS">
                        <hr className="my-2"/>
                        <Address
                            prefix="billing"
                            value={data.billing_address}
                            onChange={a => setData({billing_address: a})}
                        />
                    </Field>
                </Col>
                <Col className="ms-1 py-2" style={{ backgroundColor: '#FFE6E8' }}>
                    <Field title="SHIPPING_ADDRESS">
                        <hr className="my-2"/>
                        <Address
                            prefix="shipping"
                            value={data.shipping_address}
                            onChange={a => setData({shipping_address: a})}
                        />
                    </Field>
                </Col>
            </Row>
            { !feed && <Field title="FEED">
                <FeedSelect
                    value={data.feed}
                    onChange={feed => setData({feed, products: []})}
                />
            </Field> }
        </ModalBody>
        {data.feed && <OrderItems
            feedId={data.feed?.id}
            value={data.products}
            country={data.shipping_address?.country}
            onChange={products => setData({products})}
        />}
    </ModalEntityEditor>;
}

function OrderItems({feedId, value, onChange, country}) {
    const user = useUser();

    return <Table>
        <thead>
        <tr>
            <th className="min">#</th>
            <th className="text-center" style={{width: '60%'}}><FormattedMessage id="PRODUCT"/></th>
            <th className="text-center" style={{width: '80px'}}><FormattedMessage id="QTY"/></th>
            <th><FormattedMessage id="PRICE"/></th>
            <th/>
        </tr>
        </thead>
        <tbody>
        <MultiValue value={value} onChange={onChange}>
            {
                (row, onChange, index) => <tr key={index}>
                    <td className="min">{index + 1}.</td>
                    <td>
                        <ProductInput
                            feedId={feedId}
                            value={row?.product}
                            onChange={product => onChange({product, qty: '1'})}
                        />
                    </td>
                    <td>
                        <Input
                            type="number"
                            className="text-center"
                            value={row?.qty ?? ''}
                            onChange={a => onChange({...row, qty: a.currentTarget.value})}
                            disabled={!row}
                        />
                    </td>
                    {row && <td className="">
                        <b><FormattedMessage id="UNIT_PRICE"/>:</b> <Money value={row?.product?.price}/><br/>
                        <b><FormattedMessage id="NETTO"/>:</b> <Money value={row?.product?.price * row?.qty}/><br/>
                        <b><FormattedMessage id="VAT"/> ({country?.vat1}%):</b> <Money value={row?.product?.price * row?.qty * ((100 + (country?.vat1 || 0)) / 100) - (row?.product?.price * row?.qty)}/> <br/>
                        <b><FormattedMessage id="SHIPPING_COST"/>:</b> <Money value={user.custom_shipping ? 0 : (country?.shipping_cost * row?.qty)}/><br/>
                        <b><FormattedMessage id="SUB_TOTAL"/>:</b> <Money value={row?.product?.price * row?.qty * ((100 + (country?.vat1 || 0)) / 100) + (user.custom_shipping ? 0 : row?.qty * (country?.shipping_cost || 0))}/>
                    </td>}
                    <td>
                        {row && <Button type="button" color="danger" outline onClick={_ => onChange(null)}>
                            <i className="fas fa-trash"/>
                        </Button>}
                    </td>
                </tr>
            }
        </MultiValue>
        </tbody>
    </Table>;
}

function Address({prefix, value, onChange}) {
    const [selectedAddress, setSelectedAddress] = useState();

    return <>
        <Field title="SAVED_ADDRESS">
            <AddressSelect
                value={selectedAddress}
                onChange={address => {
                    setSelectedAddress(address);
                    onChange(address.address);
                }}
            />
        </Field>
        <AddressInput
            prefix={prefix}
            value={value}
            onChange={address => {
                setSelectedAddress(null);
                onChange(address);
            }}
        />
    </>;
}
