import React, { useMemo } from 'react';
import {Col, FormGroup, Input, Label, ModalBody, Row, Table} from "reactstrap";
import {MultiValue, useEntity} from "react-admin-base";
import {ApiSelect, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import {Field} from "../../Components/Fields";
import {DataSourceSelect, UserSelect} from "../../Components/Selects";
import {useUser} from "../../Components/UserProvider";
import AddressInput from "../../Components/AddressInput";

export default function AddressEntity({ url, onReload, id }) {
    const user = useUser();
    const entity = useEntity('/address', id, { user });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Address Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            { user.type === 512 && <Field title="USER">
                <UserSelect
                    value={data.user}
                    onChange={user => setData({ user })}
                />
            </Field> }
            <AddressInput
                prefix="address"
                value={data.address}
                onChange={address => setData({ address })}
            />
        </ModalBody>
    </ModalEntityEditor>;
}
