
import React, {useCallback, useState} from 'react';
import { ApiSelect, Relative } from 'react-admin-base-bootstrap';
import { useIntl } from 'react-intl';
import { Input } from 'reactstrap';
import AddressEntity from '../pages/Address/AddressEntity';
import DataSourceEntity from "../pages/DataSource/DataSourceEntity";
import FeedEntity from '../pages/Feed/FeedEntity';
import UserEntity from '../pages/User/UserEntity';

export function CreateSelect(props) {
    const { Component, onChange, value, isMulti } = props;

    const [ isOpen, setIsOpen ] = useState(false);

    const onReload = useCallback(function(data) {
        setIsOpen(false);
        data && onChange(isMulti ? [data] : data);
    }, [onChange, setIsOpen, isMulti]);

    return <>
        { isOpen && <Component id={value && value.id} onReload={onReload} /> }
        <ApiSelect {...props} onAddOrEdit={() => setIsOpen(true)} />
    </>;
}

export function UserSelect(props) {
    return <CreateSelect
        Component={UserEntity}
        url="/user"
        {...props}
    />;
}

export function CountrySelect(props) {
    return <ApiSelect
        url="/country"
        idKey="code"
        {...props}
    />;
}

export function LanguageSelect(props) {
    return <ApiSelect url="/language" {...props}>
        { row => <>
            { row.icon && <Relative><img style={{ width: '16px', height: '16px', marginRight: '5px' }} src={row.icon.access_url} alt={row.name} /></Relative> }
            { row.name }
        </>}
    </ApiSelect>;
}

export function DataSourceSelect(props) {
    return <CreateSelect
        Component={DataSourceEntity}
        url="/data-source"
        {...props}
    />;
}

export function FeedSelect(props) {
    return <CreateSelect
        Component={FeedEntity}
        url="/feed"
        {...props}
    />;
}

export function AddressSelect(props) {
    return <CreateSelect
        Component={AddressEntity}
        url="/address"
        {...props}
    >
        {row => <>
        { row.address.first_name } { row.address.last_name }
        </> }
    </CreateSelect>;
}
