import { Routes, Route, Navigate } from 'react-router-dom';

import Profile from "./pages/Profile/Edit";

import Users from "./pages/User/Users";
import Languages from "./pages/Language/Languages";
import { useUser } from './Components/UserProvider';
import DataSources from "./pages/DataSource/DataSources";
import Feeds from "./pages/Feed/Feeds";
import Addresses from "./pages/Address/Addresses";
import Orders from "./pages/Order/Orders";
import Countries from './pages/Country/Countries';

export default function Router() {
    const user = useUser();

    return <Routes>
        { user.type >= 512 && <Route path="/user/*" element={<Users />}/> }
        { user.type >= 512 && <Route path="/data-source/*" element={<DataSources />}/> }
        <Route path="/feed/*" element={<Feeds />}/>
        <Route path="/address/*" element={<Addresses />}/>
        <Route path="/order/*" element={<Orders />}/>
        <Route path="/country/*" element={<Countries />}/>

        <Route path="/profile" element={<Profile />}/>

        { user.type === 512 && <Route path="/language/*" element={<Languages />} /> }

        <Route path="*" element={<Navigate to="/order" />} />
    </Routes>;
}
