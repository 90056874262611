import React, { useMemo } from 'react';
import {Col, FormGroup, Input, Label, ModalBody, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ApiSelect, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import {Field} from "../../Components/Fields";
import {FormattedMessage} from "react-intl";

export default function CountryEntity({ url, onReload, id }) {
    const entity = useEntity('/country', id, { });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Country Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label><FormattedMessage id="CODE"/></Label>
                <Validator name="code" type="required">
                    <Input
                        type="text"
                        value={data.code}
                        onChange={a => setData({
                            code: a.currentTarget.value
                        })}
                        disabled={id}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label><FormattedMessage id="NAME"/></Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            <Row>
                <Col>
                    <Field title="VAT_RATE" type="numeric">
                        <Input
                            type="text"
                            value={data.vat1}
                            onChange={a => setData({
                                vat1: a.currentTarget.value
                            })}
                        />
                    </Field>
                </Col>
                <Col>
                    <Field title="SHIPPING_COSTS" type="numeric">
                        <Input
                            type="text"
                            value={data.shipping_cost}
                            onChange={a => setData({
                                shipping_cost: a.currentTarget.value
                            })}
                        />
                    </Field>
                </Col>
            </Row>
        </ModalBody>
    </ModalEntityEditor>;
}
