import {useFetch} from "react-admin-base";
import {ActionsColumn, BootstrapDataTable, Column, IdColumn, RowRenderer} from "react-admin-base-bootstrap";
import { Breadcrumb } from "react-admin-base-front";
import { useParams } from "react-router-dom";
import Money from "../../Components/Money";
import {Button, Modal, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import React from "react";

export function FeedProductTable({ id, onSelect }) {
    return <BootstrapDataTable url={"/feed/" + id + "/product"}>
        <thead>
            <tr>
                <IdColumn />
                <Column className="text-center"><FormattedMessage id="SKU"/></Column>
                <Column><FormattedMessage id="NAME"/></Column>
                <Column className="text-center"><FormattedMessage id="IN_STOCK"/></Column>
                <Column className="text-center"><FormattedMessage id="PRICE"/></Column>
                { onSelect && <ActionsColumn /> }
            </tr>
        </thead>
        <RowRenderer
            render={row => <tr key={row.id}>
                <td className="min text-center">{row.id}</td>
                <td className="min text-center">{row.sku}</td>
                <td>
                    { Object.entries(row.title).map(([key,val]) => <div><b>{key}</b>: {val}</div>) }
                </td>
                <td className="min text-center">
                    { row.inStock ? 'Yes' : 'No' }
                </td>
                <td className="min text-center">
                    <Money value={row.price} />
                </td>
                { row.inStock && onSelect && <td>
                    <Button type="button" color="primary" onClick={_ => onSelect(row)}>
                        Select
                    </Button>
                </td> }
            </tr>}
        />
    </BootstrapDataTable>;
}

export function FeedProductSelectModal({ feedId, onClose }) {
    return <Modal isOpen size="xl" toggle={_ => onClose()}>
        <ModalHeader toggle={_ => onClose()}>
            <FormattedMessage id="PRODUCT_FINDER"/>
        </ModalHeader>
        <FeedProductTable
            id={feedId}
            onSelect={onClose}
        />
        <ModalFooter>
            <Button type="button" color="danger" block>
                <FormattedMessage id="CLOSE"/>
            </Button>
        </ModalFooter>
    </Modal>;
}

export default function FeedProducts() {
    const { id } = useParams();
    const [ feed ] = useFetch('/feed/' + id);

    if (!feed)
        return null;

    return <Breadcrumb
        title="Products"
        data={
            [
                {
                    href: '/feed',
                    name: 'Feeds'
                },
                {
                    href: '/feed/' + feed.id,
                    name: feed.name
                },
                {
                    href: '/feed/' + feed.id + '/products',
                    name: 'Products'
                }
            ]
        }
    >
        <FeedProductTable
            id={feed.id}
        />
    </Breadcrumb>;
}