import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import {useCallback, useState} from 'react';
import {useAuth} from 'react-admin-base';
import {Button} from "reactstrap";
import AddressEntity from "./AddressEntity";
import React from 'react';
import {useUser} from "../../Components/UserProvider";
import {FormattedMessage} from "react-intl";


export default function Addresses() {
    const user = useUser();

    return <Breadcrumb
        title="Addresses"
        data={
        [
            {
                href: '/address',
                name: 'Addresses'
            }
        ]
    }
        >
        <CRUD url="/address" Component={AddressEntity}>
            <thead>
                <tr>
                    <IdColumn />
                    { user.type === 512 && <Column sort="user.name"><FormattedMessage id="USER"/></Column> }
                    <Column sort="address.first_name"><FormattedMessage id="FIRST_NAME"/></Column>
                    <Column sort="address.last_name"><FormattedMessage id="LAST_NAME"/></Column>
                    <Column sort="address.city"><FormattedMessage id="CITY"/></Column>
                    <Column sort="address.country.name"><FormattedMessage id="COUNTRY"/></Column>
                    <ActionsColumn />
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    { user.type === 512 && <td>{row.user.name}</td> }
                    <td>{row.address.first_name}</td>
                    <td>{row.address.last_name}</td>
                    <td>{row.address.city}</td>
                    <td>{row.address.country?.name || ''}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/address/" + row.id}
                    />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
