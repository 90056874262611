import './App.scss';

import {
    App,
    Authorized,
    AuthProvider,
    ForgotPasswordRoute,
    LoginRoute,
    NotAuthorized,
    RegisterRoute
} from 'react-admin-base';
import {DefaultValidatorOptions} from 'react-admin-base-bootstrap';
import {Login, MainLayout, Reset} from 'react-admin-base-front';

import MenuSidebar from "./MenuSidebar";
import Router from "./Router";
import MenuHeader from "./MenuHeader";
import Footer from "./Footer";
import {UserProvider} from "./Components/UserProvider";
import UploadConfig from "./UploadConfig";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LanguageProvider from './LanguageProvider';
import HardRedirect from "./Components/HardRedirect";

function BaseApp() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_PATHBASE || undefined}>
            <App
                id="dropshipping"
                name="Dropshipping"
                endpoint={process.env.REACT_APP_ENDPOINT}
                logo={(process.env.REACT_APP_PATHBASE || "") + '/logo.svg'}
                onlylogo={(process.env.REACT_APP_PATHBASE || "") + '/logo-simple.png'}
            >
                <AuthProvider
                    tokenEndpoint="/token"
                    client_id="3da4385d-5166-4c1f-9c4b-49afe82be47e"
                    client_secret="123456"
                >
                    <LanguageProvider>
                        <UploadConfig>
                            <DefaultValidatorOptions>
                                <NotAuthorized>
                                    <LoginRoute>
                                        <Login/>
                                    </LoginRoute>
                                    <ForgotPasswordRoute>
                                        <Reset/>
                                    </ForgotPasswordRoute>
                                    <RegisterRoute>
                                        <HardRedirect url="https://fussmattenprofi.com/b2b-registrieren/" />
                                    </RegisterRoute>
                                </NotAuthorized>
                                <Authorized>
                                    <UserProvider>
                                        <MainLayout>
                                            <MenuHeader/>
                                            <MenuSidebar/>
                                            <Router/>
                                            <Footer/>
                                        </MainLayout>
                                    </UserProvider>
                                </Authorized>
                            </DefaultValidatorOptions>
                        </UploadConfig>
                    </LanguageProvider>
                </AuthProvider>
            </App>
        </BrowserRouter>
    );
}

export default BaseApp;
