import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import FeedEntity from "./FeedEntity";
import React from 'react';
import {useUser} from "../../Components/UserProvider";
import {Link, Route, Routes} from 'react-router-dom';
import FeedProducts from "./FeedProducts";
import {FormattedMessage} from "react-intl";

export default function Feeds() {
    const user = useUser();
    return <Routes>
        <Route path=":id/products" element={<FeedProducts />} />
        <Route path="*" element={<Breadcrumb
            title="Feeds"
            data={
            [
                {
                    href: '/feed',
                    name: 'Feeds'
                }
            ]
        }
            >
            <CRUD url="/feed" Component={FeedEntity} noAdd={user.type !== 512}>
                <thead>
                    <tr>
                        <IdColumn />
                        <Column sort="name"><FormattedMessage id="NAME"/></Column>
                        <Column><FormattedMessage id="LINKS"/></Column>
                        <ActionsColumn />
                    </tr>
                </thead>
                <RowRenderer
                    render={row => <tr key={row.id}>
                        <td className="min text-center">{row.id}</td>
                        <td>{row.name}</td>
                        <td>
                            { Object.entries(row.links).map(([key,val]) => <div><b>{key}</b>: <a href={process.env.REACT_APP_ENDPOINT.replace(/\/$/g, '') + val}>{process.env.REACT_APP_ENDPOINT.replace(/\/$/g, '') + val}</a></div>) }
                        </td>
                        <CRUDActions
                            id={row.id}
                            edit={user.type === 512}
                            del={user.type === 512 && ("/feed/" + row.id)}
                            >
                            <Link to={row.id + "/products"} className="ms-1 btn btn-outline-primary">
                                <i className="fas fa-file-lines me-1" />
                                <FormattedMessage id="PRODUCTS"/>
                            </Link>
                        </CRUDActions>
                    </tr>}
                />
            </CRUD>
        </Breadcrumb>} />
    </Routes>;
}
