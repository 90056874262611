import {Actions, ActionsColumn, Column, CRUD, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import UserEntity, {roles} from "./UserEntity";
import {FormattedMessage} from "react-intl";
import React from "react";
import {useUser} from "../../Components/UserProvider";

export default function Users() {
    const user = useUser();

    const defaultParams = {
        sort: 'id',
        desc: true
    };

    return <Breadcrumb
        data={
            [
                {
                    href: "/user",
                    name: <FormattedMessage id={"USERS"}/>
                }
            ]
        }
    >
        <CRUD url="/user" defaultParams={defaultParams} Component={UserEntity}>
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="name"><FormattedMessage id="NAME"/></Column>
                <Column sort="email"><FormattedMessage id="EMAIL"/></Column>
                <Column sort="type"><FormattedMessage id="STATUS"/></Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.email}</td>
                    <td>{roles[row.type]}</td>
                    <Actions
                        edit={"/user/" + row.id + '/edit'}
                        del={"/user/" + row.id}
                    />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
