import {useCallback, useReducer, useState} from "react";
import {Button, Col, Input, InputGroup, Row} from "reactstrap";
import {useAuth} from "react-admin-base";
import {FeedProductSelectModal} from "../pages/Feed/FeedProducts";
import Swal from "sweetalert2";
import {FormattedMessage} from "react-intl";

export default function ProductInput({ feedId, value, onChange }) {
    const [ sku, setSku ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ select, toggleSelect ] = useReducer(a => !a, false);
    const [ api ] = useAuth();

    const findProduct = useCallback(async function() {
        if (!sku)
            return ;


        setLoading(true);
        try {
            const product = await api.tokenized.get(`/feed/${feedId}/product/${sku}`);
            if (!product.data.inStock) {
                Swal.fire('Error', 'Product not in stock', 'error');
                return ;
            }
            console.log(product.data);
            onChange(product.data);
        } catch(e) {
            Swal.fire('Error', 'Product not found', 'error');
        } finally {
            setLoading(false);
        }
    }, [sku, onChange, setLoading]);

    if (value) {
        return <span>
            { value.title?.DE || '-' }<br />
            <b>{ value.sku }</b>
        </span>;
    }

    return <InputGroup>
        <Input
            type="text"
            placeholder="SKU"
            value={sku}
            onChange={a => setSku(a.currentTarget.value)}
            disabled={value || loading}
        />
        <Button type="button" disabled={value || loading} onClick={findProduct} color="primary" >
            <i className={loading ? "fas fa-spin fa-spinner" : "fas fa-magnifying-glass"} />
        </Button>
        <Button type="button" disabled={value || loading} onClick={toggleSelect} color="info" outline>
            <i className="fas fa-list" /> <FormattedMessage id="ALL_PRODUCTS"/>
        </Button>
        { select && <FeedProductSelectModal feedId={feedId} onClose={product => {
            if (product && product.inStock) {
                onChange(product);
            }

            toggleSelect();
        }} /> }
    </InputGroup>
}