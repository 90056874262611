import {
    ActionsColumn,
    Column,
    CRUD,
    CRUDActions,
    IdColumn,
    RowRenderer,
    useDataTableContext
} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import {useFetch} from 'react-admin-base';
import {Col} from "reactstrap";
import OrderEntity from "./OrderEntity";
import React from 'react';
import {useUser} from '../../Components/UserProvider';
import {FeedSelect, UserSelect} from "../../Components/Selects";
import {FormattedMessage} from "react-intl";
import FormattedDateTime from '../../Components/FormattedDateTime';

const defaultParams = {
    sort: 'id',
    desc: true
};

export default function Orders() {
    const user = useUser();

    return <Breadcrumb
        title="Orders"
        data={
            [
                {
                    href: '/order',
                    name: 'Orders'
                }
            ]
        }
    >
        <CRUD url="/order" Component={OrderEntity} defaultParams={defaultParams}>
            <thead>
            <tr>
                <th colSpan={user.type === 512 ? 3 : 2}/>
                <th colSpan={2} className="text-center"><FormattedMessage id="SHIPPING"/></th>
                <th colSpan={2} className="text-center"><FormattedMessage id="BILLING"/></th>
                <th colSpan={3}/>
            </tr>
            <tr>
                <IdColumn/>
                {user.type === 512 && <Column>User</Column>}
                <Column>Feed</Column>

                <Column><FormattedMessage id="FIRST_NAME"/></Column>
                <Column><FormattedMessage id="LAST_NAME"/></Column>
                <Column sort="created_at"><FormattedMessage id="DATE"/></Column>

                <Column><FormattedMessage id="FIRST_NAME"/></Column>
                <Column><FormattedMessage id="LAST_NAME"/></Column>

                <Column><FormattedMessage id="STATUS"/></Column>

                <Column><FormattedMessage id="PRODUCT"/></Column>

                <ActionsColumn/>
            </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    {user.type === 512 && <td>{row.user?.name || ''}</td>}
                    <td>{row.feed?.name || ''}</td>
                    <td>{row.shipping_address?.first_name || '-'}</td>
                    <td>{row.shipping_address?.last_name || '-'}</td>
                    <td><FormattedDateTime value={row.created_at} /></td>
                    <td>{row.billing_address?.first_name || '-'}</td>
                    <td>{row.billing_address?.last_name || '-'}</td>

                    <td><OrderStatus status={row.status}/></td>

                    <td>{row.products.length} products<br/></td>

                    <CRUDActions
                        id={row.id}
                        edit
                    />
                </tr>}
            />
            <>
                {user.type === 512 && <Col>
                    <UserFilter/>
                </Col>}
                <Col>
                    <FeedFilter/>
                </Col>
            </>
        </CRUD>
    </Breadcrumb>;
}

function UserFilter() {
    const [params, setParams] = useDataTableContext();
    const [user] = useFetch(params.userId && ('/user/' + params.userId));

    return <UserSelect
        value={user}
        onChange={user => setParams({...params, userId: user && user.id})}
        placeholder="Filter by User"
    />;
}

function FeedFilter() {
    const [params, setParams] = useDataTableContext();
    const [feed] = useFetch(params.feedId && ('/feed/' + params.feedId));

    return <FeedSelect
        value={feed}
        onChange={feed => setParams({...params, feedId: feed && feed.id})}
        placeholder="Filter by Feed"
    />;
}

function OrderStatus({status}) {
    if (!status)
        return null;

    return <span style={{'background': '#' + (1 << 24 | status.color).toString(16).slice(1), 'padding': '5px 10px', fontWeight: 'bold'}}>{status.name}</span>;
}
