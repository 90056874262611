import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import React, {useCallback, useState} from 'react';
import {useAuth} from 'react-admin-base';
import {Button} from "reactstrap";
import CountryEntity from "./CountryEntity";
import Money from "../../Components/Money";
import {FormattedMessage} from "react-intl";

const defaultParams = { sort: 'code' };

export default function Countries() {
    return <Breadcrumb
        title="Countries"
        data={
        [
            {
                href: '/country',
                name: 'Countries'
            }
        ]
    }
        >
        <CRUD url="/country" Component={CountryEntity} defaultParams={defaultParams}>
            <thead>
                <tr>
                    <IdColumn />
                    <Column sort="name"><FormattedMessage id="NAME"/></Column>
                    <Column sort="vat1" className="text-center"><FormattedMessage id="VAT_RATE"/></Column>
                    <Column sort="shipping_cost" className="text-center"><FormattedMessage id="SHIPPING_COSTS"/></Column>
                    <ActionsColumn />
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.code}>
                    <td className="min text-center">{row.code}</td>
                    <td>{row.name}</td>
                    <td className="text-center">{row.vat1 || 0}%</td>
                    <td className="text-center"><Money value={row.shipping_cost || 0} /></td>
                    <CRUDActions
                        id={row.code}
                        edit
                        del={"/country/" + row.id}
                     />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
