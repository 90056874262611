import { Validator } from "react-admin-base-bootstrap";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {FormattedMessage} from "react-intl";
import React from "react";
import { Field } from "./Fields";
import {CountrySelect} from "./Selects";

export default function AddressInput({ prefix, value, onChange }) {
	prefix = prefix || '';

	return <>
        <Row>
            <Col>
                <Field title="FIRST_NAME" type="required">
                    <Input
                        type="text"
                        value={value?.first_name || ''}
                        onChange={a => onChange({ ...(value || {}), first_name: a.currentTarget.value })}
                    />
                </Field>
            </Col>
            <Col>
                <Field title="LAST_NAME" type="required">
                    <Input
                        type="text"
                        value={value?.last_name || ''}
                        onChange={a => onChange({ ...(value || {}), last_name: a.currentTarget.value })}
                    />
                </Field>
            </Col>
        </Row>
        <Row>
            <Col>
                <Field title="COMPANY_NAME">
                    <Input
                        type="text"
                        value={value?.company || ''}
                        onChange={a => onChange({ ...(value || {}), company: a.currentTarget.value })}
                    />
                </Field>
            </Col>
            <Col>
                <Field title="VAT_NUMBER">
                    <Input
                        type="text"
                        value={value?.vat_number || ''}
                        onChange={a => onChange({ ...(value || {}), vat_number: a.currentTarget.value })}
                    />
                </Field>
            </Col>
        </Row>
        <Row>
            <Col>
                <Field title="EMAIL" type="required">
                    <Input
                        type="text"
                        value={value?.email || ''}
                        onChange={a => onChange({ ...(value || {}), email: a.currentTarget.value })}
                    />
                </Field>
            </Col>
            <Col>
                <Field title="PHONE" type="required">
                    <Input
                        type="text"
                        value={value?.phone || ''}
                        onChange={a => onChange({ ...(value || {}), phone: a.currentTarget.value })}
                    />
                </Field>
            </Col>
        </Row>

		<Row>
			<Col>
				<FormGroup>
					<Label><FormattedMessage id="ZIP"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
					<Validator name={prefix + "zip"} type="required">
						<Input
							type="text"
							value={(value && value.zip) || ''}
							onChange={a => onChange({ ...(value || {}), zip: a.currentTarget.value })}
						/>
					</Validator>
				</FormGroup>
			</Col>
			<Col>
				<FormGroup>
					<Label><FormattedMessage id="CITY"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
					<Validator name={prefix + "city"} type="required">
						<Input
							type="text"
							value={(value && value.city) || ''}
							onChange={a => onChange({ ...(value || {}), city: a.currentTarget.value })}
						/>
					</Validator>
				</FormGroup>
			</Col>
		</Row>
		<Row>
			<Col>
				<FormGroup>
					<Label><FormattedMessage id="STREET"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
					<Validator name={prefix + "street"} type="required">
						<Input
							type="text"
							value={(value && value.street) || ''}
							onChange={a => onChange({ ...(value || {}), street: a.currentTarget.value })}
						/>
					</Validator>
				</FormGroup>
			</Col>
			<Col>
				<FormGroup>
					<Label><FormattedMessage id="HOUSE_NUMBER"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
					<Validator name={prefix + "house_no"} type="required">
						<Input
							type="text"
                            value={(value && value.house_number) || ''}
                            onChange={a => onChange({ ...(value || {}), house_number: a.currentTarget.value })}
						/>
					</Validator>
				</FormGroup>
			</Col>
		</Row>
        <Row>
            <Col>
                <Field title="STATE">
                    <Input
                        type="text"
                        value={value?.state || ''}
                        onChange={a => onChange({ ...(value || {}), state: a.currentTarget.value })}
                    />
                </Field>
            </Col>
            <Col>
                <Field title="COUNTRY" type="required">
                    <CountrySelect
                        value={value?.country}
                        onChange={a => onChange({ ...(value || {}), country: a })}
                    />
                </Field>
            </Col>
        </Row>
	</>;
}