import React from 'react';
import {Button, FormGroup, Input, Label, ModalBody, Table} from "reactstrap";
import {MultiValue, useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import {Field} from "../../Components/Fields";
import {DataSourceSelect, UserSelect} from "../../Components/Selects";
import {FormattedMessage} from 'react-intl';

export default function FeedEntity({url, onReload, id}) {
    const entity = useEntity('/feed', id, { percent_discount: '0' });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Feed Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label><FormattedMessage id="NAME"/></Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>

            <Field title="Data Source" type="required">
                <DataSourceSelect
                    value={data.data_source}
                    onChange={a => setData({
                        data_source: a
                    })}
                />
            </Field>

            <Field title="Discount %" type="numeric">
                <Input
                    type="number"
                    value={data.percent_discount || ''}
                    onChange={a => setData({
                        percent_discount: a.currentTarget.value
                    })}
                />
            </Field>

            <FormGroup>
                <Label><FormattedMessage id="USERS"/></Label>
                <UserSelect
                    value={data.users}
                    onChange={users => setData({users})}
                    isMulti
                />
            </FormGroup>

            <FormGroup>
                <Label><FormattedMessage id="SHOW_ONLY_SELECTED_ITEMS"/></Label>
                <CheckBox type="checkbox" checked={!!data.show_only}
                          onChange={a => setData({show_only: !data.show_only})}
                          label="Show only selected items" />
            </FormGroup>

            <DiscountEditor
                value={data.filters}
                onChange={filters => setData({filters})}
            />

        </ModalBody>
    </ModalEntityEditor>;
}

function DiscountEditor({value, onChange}) {
    return <Table>
        <thead>
        <tr>
            <th>
                <FormattedMessage id="CATEGORY"/>
            </th>
            <th>
                <FormattedMessage id="SKU"/>
            </th>
            <th>
                <FormattedMessage id="FIXED_PRICE"/>
            </th>
            <th>
                <FormattedMessage id="DISCOUNT"/>
            </th>
            <th/>
        </tr>
        </thead>
        <tbody>
        <MultiValue value={value} onChange={onChange}>
            {
                (row, onChange, index) => <tr key={index}>
                    <td>
                        <Input
                            type="text"
                            value={row?.category || ''}
                            onChange={a => onChange({...(row || {}), category: a.currentTarget.value || null, sku: null})}
                            disabled={!!row?.sku}
                        />
                    </td>
                    <td>
                        <Input
                            type="text"
                            value={row?.sku || ''}
                            onChange={a => onChange({...(row || {}), sku: a.currentTarget.value || null, category: null})}
                            disabled={!!row?.category}
                        />
                    </td>
                    <td>
                        <Input
                            type="number"
                            value={row?.fixed_price || ''}
                            onChange={a => onChange({...row, fixed_price: a.currentTarget.value || null, percent_price: null})}
                            disabled={!row || row.percent_price}
                        />
                    </td>
                    <td>
                        <Input
                            type="number"
                            value={row?.percent_price || ''}
                            onChange={a => onChange({...row, percent_price: a.currentTarget.value || null, fixed_price: null})}
                            disabled={!row || row.fixed_price}
                        />
                    </td>
                    <td>
                        {row && <Button color="danger" type="button" outline onClick={_ => onChange(null)}>
                            <i className="fas fa-trash"/>
                        </Button>}
                    </td>
                </tr>
            }
        </MultiValue>
        </tbody>
    </Table>;
}
