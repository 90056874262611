import { useMemo } from 'react';
import { useFreeFetch } from 'react-admin-base';
import {LanguageProvider as BootstrapLanguageProvider} from 'react-admin-base-bootstrap';

function useLanguage(language) {
	//const [ language ] = useFetch('/language/' + language.id);
	const [ messages ] = useFreeFetch(language && language.url);
	return messages||{};
}

export default function LanguageProvider({ children }) {
	const [ languages ] = useFreeFetch('/language');

	const allLanguages = useMemo(() => languages && languages.data
		.reduce((a, b) => ({
			...a,
			[b.language_code]: {
				id: b.id,
				name: b.name,
				icon: b.icon && process.env.REACT_APP_ENDPOINT?.replace(/\/$/, "") + b.icon.access_url,
				url: b.precompiled_json && b.precompiled_json.access_url
			}
	}), {}), [languages]);

	if (!allLanguages)
		return null;

	return <BootstrapLanguageProvider defaultLanguage="de-DE" languages={allLanguages} loader={useLanguage}>
		{ children }
	</BootstrapLanguageProvider>
}
