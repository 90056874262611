import React, { useMemo } from 'react';
import {Col, FormGroup, Input, Label, ModalBody, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ApiSelect, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import {FormattedMessage} from "react-intl";

const integrationTypes = {
    2: {
        name: "BillBee",
        component({ value, onChange }) {
            return <>
                <FormGroup>
                    <Label>API Key</Label>
                    <Validator name="api_key" type="required">
                        <Input
                            type="text"
                            value={(value && value.api_key) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                api_key: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label><FormattedMessage id="USERNAME"/></Label>
                            <Validator name="username" type="required">
                                <Input
                                    type="text"
                                    value={(value && value.username) || ''}
                                    onChange={a => onChange({
                                        ...(value || {}),
                                        username: a.currentTarget.value
                                    })}
                                />
                            </Validator>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label><FormattedMessage id="USERNAME"/></Label>
                            <Validator name="password" type="required">
                                <Input
                                    type="text"
                                    value={(value && value.password) || ''}
                                    onChange={a => onChange({
                                        ...(value || {}),
                                        password: a.currentTarget.value
                                    })}
                                />
                            </Validator>
                        </FormGroup>
                    </Col>
                </Row>
            <FormGroup>
                <Label><FormattedMessage id="USERNAME"/></Label>
                <Validator name="shopId" type="required|numeric">
                    <Input
                        type="text"
                        value={(value && value.shopId) || ''}
                        onChange={a => onChange({
                            ...(value || {}),
                            shopId: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            </>;
        }
    }
};

function IntegrationTypeSelect({ value, onChange }) {
    const options = useMemo(() => Object.entries(integrationTypes)
        .map(([key, val]) => ({ id: key, name: val.name })), []);

    return <ApiSelect
        staticOptions={options}
        value={value && options.find(a => +a.id === value)}
        onChange={type => onChange(type && type.id)}
    />;
}

export default function DataSourceEntity({ url, onReload, id }) {
    const entity = useEntity('/data-source', id, { });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    const Component = data.type && integrationTypes[data.type].component;

    return <ModalEntityEditor size="lg" title="Data Source Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label><FormattedMessage id="NAME"/></Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label><FormattedMessage id="TYPE"/></Label>
                <IntegrationTypeSelect
                    value={data.type}
                    onChange={a => setData({ type: a })}
                />
            </FormGroup>
            { Component && <Component
                value={data.options}
                onChange={options => setData({ options })}
            /> }
        </ModalBody>
    </ModalEntityEditor>;
}
