import React from 'react';
import {Card, CardBody, Col, Input, Label, Row, ModalBody, FormGroup} from "reactstrap";
import {useEntity} from "react-admin-base";
import {Validator, ModalEntityEditor, PasswordInput, CheckBox} from 'react-admin-base-bootstrap';
import {useUser} from "../../Components/UserProvider";
import AddressInput from '../../Components/AddressInput';
import {FormattedMessage} from "react-intl";
import {CountrySelect, FeedSelect} from "../../Components/Selects";

export const roles = {
    '512': 'Admin',
    '8': 'User'
};

export default function UserEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/user', id, { type: 512 });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="xl" title="Benutzer-Administration" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label><FormattedMessage id="NAME"/></Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name || ''}
                        onChange={a => setData({name: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label><FormattedMessage id="EMAIL"/></Label>
                <Validator name="email" type="required">
                    <Input type="text" value={data.email || ''}
                           onChange={a => setData({email: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

            <PasswordInput
                value={data.password || ''}
                onChange={a => setData({password: a})}
            />

            <FormGroup>
                <Label><FormattedMessage id="PHONE"/></Label>
                <Input type="text" value={data.phone || ''}
                       onChange={a => setData({phone: a.currentTarget.value})}/>
            </FormGroup>

            <FormGroup>
                <Label><FormattedMessage id="CUSTOM_SHIPPING"/></Label>
                <CheckBox type="checkbox" checked={!!data.custom_shipping}
                    onChange={a => setData({custom_shipping: !data.custom_shipping})}
                label="No shipping pricing" />
            </FormGroup>

            <FormGroup>
                <Label><FormattedMessage id="FEEDS"/></Label>
                <FeedSelect
                    value={data.feeds}
                    onChange={feeds => setData({ feeds })}
                    isMulti
                />
            </FormGroup>
            { data.type !== 512 && <FormGroup>
                <Label>Country</Label>
                <CountrySelect
                    value={data.countries}
                    onChange={a => setData({ countries: a })}
                    isMulti
                />
            </FormGroup> }
            { user.type === 512 && <FormGroup>
                <Label>Status</Label>
                <Input
                    type="select"
                    value={data.type}
                    onChange={a => setData({type: +a.currentTarget.value, supplier:null})}
                >
                    { Object.entries(roles).filter(([id]) => id <= user.type).map(([id, role]) => <option value={id} key={id}>{role}</option>)}
                </Input>
            </FormGroup> }
        </ModalBody>
    </ModalEntityEditor>;
}
